import React from 'react'
import styles from './ClientProject.module.scss'
import Skeleton from 'react-loading-skeleton'
import cn from 'classnames'
import harvestNav from '../../utils/harvest-nav'

export default props => {
  const { loading, project, client, reducedMargin = false } = props

  return (
    <div className={cn({ [styles.reducedMargin]: reducedMargin })}>
      <h6 className={styles.client}>
        {client ? (
          <a href={harvestNav.client(client.id)} target='_blank'>
            {client.name}
          </a>
        ) : (
          loading && <Skeleton width='25%' />
        )}
      </h6>

      <h4 className={styles.project}>
        {project ? (
          <a href={harvestNav.project(project.id)} target='_blank'>
            {project.name}
          </a>
        ) : (
          loading && <Skeleton width='56%' />
        )}
      </h4>
    </div>
  )
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react'
import styles from './Editor.module.scss'
import {
  Editor,
  EditorState,
  convertToRaw
} from 'draft-js'
import { draftToMarkdown } from 'markdown-draft-js'

export default ({ session, onChange, placeholder, readOnly }) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const editor = useRef()

  useEffect(() => {
    setEditorState(EditorState.createEmpty())
  }, [session])

  return (
    <div
      className={`${styles.container} ${readOnly ? styles.readOnly : ''}`}
      onClick={() => editor.current.focus()}
    >
      <Editor
        stripPastedStyles
        ref={editor}
        readOnly={readOnly}
        placeholder={placeholder || 'Skrifaðu eitthvað...'}
        editorState={editorState}
        onChange={newState => {
          const markdown = draftToMarkdown(
            convertToRaw(newState.getCurrentContent())
          )

          setEditorState(newState)

          onChange && onChange(markdown)
        }}
      />
    </div>
  )
}

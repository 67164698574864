import React, { useContext } from 'react'
import { useApi } from './api'

const Ctx = React.createContext({})

export const useSharedHarvestData = () => {
  return useContext(Ctx)
}

export const SharedHarvestDataProvider = ({ children }) => {
  const {
    data,
    error
  } = useApi(`/harvest/users`)

  const sharedData = {
    users: data && data.users.reduce((acc, user) => ({
      ...acc,
      [user.id]: user
    }), {})
  }

  if (error) {
    console.error(error)
  }

  return (
    <Ctx.Provider value={sharedData}>
      {children}
    </Ctx.Provider>
  )
}

import React from 'react'
import styles from './PurpleArea.module.scss'

export default ({ children, ...props }) => (
  <div
    {...props}
    className={styles.container}
  >
    {children}
  </div>
)

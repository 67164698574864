import React from 'react'
import Fade from 'react-reveal/Fade'

const FadeBottom = ({ children, duration = 500, ...rest }) => {
  return (
    <Fade bottom duration={duration} distance={'40px'} {...rest}>
      {children}
    </Fade>
  )
}

export default FadeBottom

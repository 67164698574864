import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useApi, useHttpClient } from '../../utils/api'
import {
  Editor,
  FadeBottom,
  ProgressBar,
  Project,
  Button,
  User,
  Total,
  ClientProject
} from '../../components'
import styles from './ProjectInSidePanel.module.scss'
import { formatDate, categorizeList } from '../../utils'
import MarkdownIt from 'markdown-it'
import { Fade } from 'react-reveal'

const md = MarkdownIt()

export default ({ projectId }) => {
  const { loading, data, error } = useApi(`/project/${projectId}`)

  const { project, totalHours } = data || {}

  const [editingSession, setEditingSession] = useState(Date.now())
  const [draft, setDraft] = useState('')
  const [disabled, setDisabled] = useState(false)
  const isDraftValid = draft.trim().length > 1
  const httpClient = useHttpClient()

  const saveDraftToGoal = () => {
    setDisabled(true)

    httpClient
      .post(`/project/${projectId}/goal`, {
        text: draft
      })
      .then(() => {
        setEditingSession(Date.now())
      })
      .catch(e => {
        console.error(e)
        window.alert('villa kom upp við að vista markmið')
      })
      .finally(() => {
        setDraft('')
        setDisabled(false)
      })
  }

  const userAssignments = (project && project.userAssignments) || []

  const { projectManagers, assignees } = categorizeList(userAssignments, {
    projectManagers: u => u.is_project_manager,
    assignees: u => true
  })

  const clientProjectProps = project && {
    client: {
      id: project.clientId,
      name: project.clientName
    },
    project: {
      id: project.projectId,
      name: project.name
    }
  }

  return (
    <Project>
      {error && 'ther was eror'}

      {(data || loading) && (
        <div>
          <ClientProject loading={loading} {...clientProjectProps} />

          <div className='row mt-4 mb-4'>
            {!data && loading && <Skeleton />}

            {data && (
              <div className='col-7'>
                {project.budget ? (
                  <FadeBottom delay={100}>
                    <ProgressBar
                      budget={project.budget || 0}
                      total={totalHours}
                    />
                  </FadeBottom>
                ) : (
                  <Total total={totalHours} />
                )}
              </div>
            )}

            <div className='col-5 text-right'>
              {projectManagers &&
                projectManagers.map(({ user: { id } }) => (
                  <User
                    key={id}
                    fade={{
                      right: true,
                      distance: '40px'
                    }}
                    userId={id}
                  />
                ))}
            </div>
          </div>

          {!loading && (
            <FadeBottom delay={300} duration={300}>
              <div className='mb-2'>
                <h6 className='mb-0'>Markmið</h6>

                <Editor
                  placeholder='Markmið vikunnar...'
                  readOnly={disabled}
                  session={editingSession}
                  onChange={markdown => {
                    setDraft(markdown)
                  }}
                />
              </div>
            </FadeBottom>
          )}

          <div className='mb-4'>
            <Fade
              right
              duration={300}
              distance={'80px'}
              collapse
              when={isDraftValid}
            >
              <Button disabled={disabled} onClick={saveDraftToGoal}>
                Committa á markmið
              </Button>
            </Fade>
          </div>

          {!loading && (
            <FadeBottom delay={450} duration={300}>
              <div className='mb-4'>
                <LastGoal projectId={projectId} session={editingSession} />
              </div>
            </FadeBottom>
          )}

          <div className='mb-4'>
            {assignees &&
              assignees.map(({ user: { id } }, i) => (
                <User
                  fade={{ delay: 600 + i * 40, bottom: true }}
                  key={id}
                  userId={id}
                />
              ))}
          </div>
        </div>
      )}
    </Project>
  )
}

const LastGoal = ({ projectId, session }) => {
  const { data, error } = useApi(
    `/project/${projectId}/goals?${session}`,
    false
  )

  if (data && data.length < 1) {
    return null
  }

  const [last] = data || []

  return (
    <div>
      {last && <h6 className='mb-0'>Síðustu markmið</h6>}

      {error && <div className='error'>{error.toString()}</div>}

      {last && (
        <div className={styles.lastGoal}>
          <div
            className={styles.lastGoal__text}
            dangerouslySetInnerHTML={{
              __html: md.render(last.text)
            }}
          />

          <div className={styles.lastGoal__date}>
            {formatDate(new Date(last.date))}
          </div>
        </div>
      )}
    </div>
  )
}

import React from 'react'
import classNames from 'classnames'
import styles from './Card.module.scss'

export default props => {
  const {
    withPadding = false,
    reducedMargin = false,
    ...rest
  } = props

  return (
    <div
      className={
        classNames({
          [styles.card]: true,
          [styles.cardWithPadding]: withPadding,
          [styles.cardReducedMargin]: reducedMargin
        })
      }
      {...rest}
    />
  )
}

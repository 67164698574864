import { differenceInCalendarDays, format } from 'date-fns'
import { is } from 'date-fns/locale'

export const parseLinkString = arg => {
  const str = Array.isArray(arg) ? arg[0] : arg
  const lines = str
    .split('\n')
    .map(s => s.trim())
    .filter(Boolean)

  return lines.map(line => {
    const parts = line.split(' ')

    return {
      url: parts.slice(-1).join(' '),
      title: parts
        .slice(0, parts.length - 1)
        .join(' ')
        .trim()
    }
  })
}

export const categorizeList = (list, categorizer) => {
  const entries = Object.entries(categorizer)
  const initial = entries.reduce(
    (acc, [category]) => ({
      ...acc,
      [category]: []
    }),
    {}
  )

  return list.reduce((acc, item) => {
    entries.map(([category, fn]) => {
      if (fn(item)) {
        acc[category].push(item)
      }
    })

    return acc
  }, initial)
}

const getRelativeFormat = diff => {
  if (diff > 100) {
    return 'd. MMM, yyyy'
  }

  if (diff < 1) {
    return "'Í dag' HH:ii"
  }

  return 'EEEE d. MMM'
}

export const formatDate = date => {
  const diff = differenceInCalendarDays(Date.now(), date)

  const formatStr = getRelativeFormat(diff)

  return format(date, formatStr, { locale: is })
}

export const getAllBillingPeriods = today => {
  const START_YEAR = 2013
  return indexedArray(today.getFullYear() - START_YEAR)
    .map(i => {
      return START_YEAR + 1 + i
    })
    .map(year => {
      const months = indexedArray(12)
        .map(month0indx => {
          const date = new Date(year, month0indx, 1)
          const label = format(date, `MMMM yyyy`, { locale: is })
          const month = month0indx + 1
          const key = `${year}-${month < 10 ? `0${month}` : month}`

          return {
            date,
            year,
            month,
            label,
            key
          }
        })
        .filter(({ date }) => date.getTime() < today.getTime())

      return {
        year,
        months
      }
    })
    .reduce(
      (acc, yearObj) => {
        acc.byYear.push(yearObj)
        acc.byKey = {
          ...acc.byKey,
          ...yearObj.months.reduce(
            (acc, month) => ({
              ...acc,
              [month.key]: month
            }),
            {}
          )
        }
        return acc
      },
      { byYear: [], byKey: {} }
    )
}

export const indexedArray = size => {
  return new Array(size).fill(null).map((x, i) => i)
}

export const categorizeListByFn = (list, fn) => {
  return list.reduce((acc, item) => {
    const cat = fn(item)

    if (!acc[cat]) {
      acc[cat] = []
    }

    acc[cat].push(item)

    return acc
  }, {})
}

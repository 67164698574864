import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import { Navigation, Logo, Footer } from '../../components/'
import { SidePanelContainer } from '../../components/SidePanel'
import '../../styles/index.scss'
import styles from './Main.module.scss'

export default ({ children, full = false, noMenu = false }) => {
  const mainClasses = classNames(
    'bg-white',
    { 'pt-5': !full },
    styles.container
  )

  return (
    <Fragment>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Hjarta Kosmos & Kaos</title>
        <link
          data-react-helmet='true'
          rel='icon'
          type='image/gif'
          href='/kk-fav.gif'
        />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
        <html lang='is' />
      </Helmet>

      {!noMenu && (
        <Navigation
          white={false}
          hasSubNav={false}
          reverseColor={false}
          offset={0}
        />
      )}

      <Logo
        moveValue={150}
        color='#251D4E'
        secondaryColor='#251D4E'
      />

      <main id='main' className={mainClasses}>
        {children}
      </main>

      <Footer isRed={false} />

      <SidePanelContainer />
    </Fragment>
  )
}

import React from 'react'
import styles from './Total.module.scss'

export default ({ total }) => {
  return (
    <div title='Heildartími' className={styles.total}>
      {total.toLocaleString('de-DE', { maximumFractionDigits: 1 })}
    </div>
  )
}

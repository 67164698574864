import React from 'react'
import styles from './User.module.scss'
import { useSharedHarvestData } from '../../utils/shared-data'
import { Fade } from 'react-reveal'

const getFirstName = name => (
  name.split(' ').slice(0, 1).join()
)

const User = ({ userId, fade = {} }) => {
  const { users } = useSharedHarvestData()

  if (!users) {
    return null
  }

  const user = users[userId]

  if (!user) {
    console.warn(`user with id ${userId} not found in users`, { users })
    return null
  }

  return (
    <Fade
      duration={300}
      {...fade}
      distance='40px'
    >
      <div
        className={styles.user}
        title={user.first_name}
      >
        <div>
          <div
            className={styles.user__avatar}
            style={{
              backgroundImage: `url(${user.avatar_url})`
            }}
          />

          {getFirstName(user.first_name)}
        </div>
      </div>
    </Fade>
  )
}

export default User

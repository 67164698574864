const baseUrl = 'https://kosmosogkaos.harvestapp.com'

export default {
  project (id) {
    return `${baseUrl}/projects/${id}`
  },

  client (id) {
    return `${baseUrl}/projects?client=${id}&filter=active`
  }
}

import React from 'react'
import styles from './Button.module.scss'

export default ({ children, title, ...props }) => (
  <button
    {...props}
    title={title}
    className={styles.button}
  >
    {children || title || 'no caption'}
  </button>
)

import React, { useState, useContext, useEffect, useRef } from 'react'
import styles from './SidePanel.module.scss'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames'
import { MdClose } from 'react-icons/md'

const Ctx = React.createContext({})

const isElementInside = (el, container) => {
  if (!el) {
    return false
  }

  if (el === container) {
    return true
  }

  return isElementInside(el.parentNode, container)
}

export const SidePanelContainer = () => {
  const ref = useRef()
  const { options, renderer, shown = false } = useContext(Ctx)
  const { onRequestClose } = options || {}

  useEffect(() => {
    if (!shown || !onRequestClose) {
      return
    }

    const clickOutside = e => {
      if (!isElementInside(e.target, ref.current)) {
        onRequestClose()
      }
    }

    const keyEsc = e => {
      if (e.keyCode === 27) {
        onRequestClose()
      }
    }

    // bind keyboard etc
    document.addEventListener('keydown', keyEsc)
    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('keydown', keyEsc)
      document.removeEventListener('click', clickOutside)
    }
  }, [ref, options, shown])

  return (
    <div ref={ref}>
      <Fade
        delay={0}
        right
        duration={500}
        distance={'200px'}
        when={shown}
      >
        <div className={classNames(
          styles.container,
          { [styles.container__hidden]: !shown }
        )}>
          {renderer}

          <button
            className={styles.close}
            onClick={onRequestClose}
          >
            <MdClose />
          </button>
        </div>
      </Fade>
    </div>
  )
}

export const SidePanelProvider = ({ children }) => {
  const [renderer, setRenderer] = useState(null)
  const [shown, setShown] = useState(false)
  const [options, setOptions] = useState({})

  return (
    <Ctx.Provider
      value={{
        renderer,
        setRenderer,
        shown,
        setShown,
        options,
        setOptions
      }}
    >
      {children}
    </Ctx.Provider>
  )
}

const SidePanel = ({ children, shown = false, options }) => {
  const { setRenderer, setShown, setOptions } = useContext(Ctx)

  useEffect(() => {
    setOptions(options)
    setShown(shown)
    setRenderer(() => children)
  }, [options, shown])

  return null
}

export default SidePanel

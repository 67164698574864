import React from 'react'
import styles from './Navigation.module.scss'
import classNames from 'classnames'
import { Link } from '..'

const menu = [
  ['Upphaf', '/'],
  // ['Áætlanir', '/estimates'],
  // ['Vefir', '/sites'],
  ['Verkefni', '/projects']
]

const Navigation = ({ white }) => {
  const linkClass = classNames(
    'hdln--nav',
    'ml-md-4',
    'hdln--hover-pink',
    { 'hdln--white': white }
  )

  const navClass = classNames(
    'float-md-right px-3 px-md-0 py-4 pr-md-100 d-flex d-md-block justify-content-between',
    { [styles.navWhite]: white }
  )

  return (
    <header className={styles.header}>
      <nav className={navClass}>
        {menu.map(([title, url]) => (
          <Link
            key={url}
            to={url}
            activeClassName='hdln--nav-active'
            className={linkClass}
          >
            <span word={title}>{title}</span>
          </Link>
        ))}
      </nav>
    </header>
  )
}

export default Navigation

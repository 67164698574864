import React from 'react'
import styles from './FooterBottom.module.scss'
import classNames from 'classnames'
import { FadeBottom } from '../'

const FooterBottom = ({ isHeader }) => {
  const containerClass = classNames(
    styles.container,
    'container',
    { 'my-5 my-md-100': !isHeader },
    { 'hdln--white pt-4 pt-md-6': isHeader }
  )

  const headerClass = classNames(
    styles.footerLowerHdln,
    { 'hdln--white': isHeader }
  )

  const pinkLink = classNames(
    { 'link--social-white': isHeader }
  )

  return (
    <div className={containerClass}>
      <div className='row'>
        <div className='col-md-3'>
          <FadeBottom>
            <h2 className={headerClass}>Contact Us</h2>
            <ul className={styles.footerInfo}>
              <li>
                <a href='tel:+3545611020'>Ice: +354 561 1020</a>
              </li>
              <li>
                <a href='tel:+46841035200'>Swe: +46 8 410 352 00</a>
              </li>
              <li><strong><a className={pinkLink} href='mailto:hello@kosmosogkaos.is'>hello@kosmosogkaos.is</a></strong></li>
            </ul>
          </FadeBottom>
        </div>

        <div className='col-6 col-md-2 pt-4 pt-md-0'>
          <FadeBottom delay={100}>
            <h2 className={headerClass}>Visit Us</h2>
            <ul className={styles.footerInfo}>
              <li>Hólmaslóð 4</li>
              <li>101 Reykjavík</li>
              <li>Iceland</li>
            </ul>
          </FadeBottom>
        </div>

        <div className={classNames(styles.noheader, 'col-6 col-md-2 mt-4 mt-md-0 pr-0')}>
          <FadeBottom delay={200}>
            <ul className={styles.footerInfo}>
              <li>Malmskillnadsgatan 32</li>
              <li>111 51 Stockholm</li>
              <li>Sweden</li>
            </ul>
          </FadeBottom>
        </div>

        <div className='col-6 col-md-2 pt-4 pt-md-0 offset-md-1'>
          <FadeBottom delay={300}>
            <h2 className={headerClass}>Follow Us</h2>
            <ul className={styles.footerInfo}>
              <li><strong><a className={pinkLink} href='https://www.linkedin.com/company/kosmos-&-kaos'>linkedin</a></strong></li>
              <li><strong><a className={pinkLink} href='https://www.instagram.com/kosmosandkaos/?hl=en'>instagram</a></strong></li>
              <li><strong><a className={pinkLink} href='https://www.facebook.com/kosmosogkaos/'>facebook</a></strong></li>
            </ul>
          </FadeBottom>
        </div>

        {/* <div className='col-6 col-md-2 pt-4 pt-md-0'>
          <FadeBottom delay={400}>
            <h2 className={headerClass}>View this</h2>
            <ul className={styles.footerInfo}>
               <li><strong><a href='/'>medium</a></strong></li>
               <li><strong><a href='/careers'>careers</a></strong></li>
               <li><strong><a href='/'>svenska</a></strong></li>
            </ul>
          </FadeBottom>
        </div> */}
      </div>
    </div>
  )
}

export default FooterBottom

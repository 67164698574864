import React from 'react'
import classNames from 'classnames'
import styles from './ProgressBar.module.scss'

export default ({ budget, total }) => {
  if (!budget) {
    return null
  }

  const ratio = budget > 0
    ? total / budget
    : 0

  const progressBarClasses = classNames(
    'flex-shrink-0',
    styles.progressBar,
    { [styles.progressBarNoBudget]: budget < 1 }
  )

  return (
    <div className='d-flex align-items-center'>
      <div className={progressBarClasses}>
        <div
          className={classNames(
            styles.progressBar__completed,
            { [styles.progressBar__completedWarning]: budget - total < 0 }
          )}
          style={{
            width: `${Math.round(Math.min(1, ratio) * 100)}%`
          }}
        />
        <span className={styles.progressBar__label}>{total}</span>
      </div>

      <div
        title='Áætlun'
        className={`flex-shrink-0 ${styles.progressBar__budget}`}
      >{budget}</div>
    </div>
  )
}

import React from 'react'
import styles from './Project.module.scss'

export default React.forwardRef(({ className, ...rest }, ref) => {
  const cls = `${styles.project} ${(className || '')}`

  return (
    <div
      ref={ref}
      className={cls}
      {...rest}
    />
  )
})
